* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.go-time-text {
  position: absolute;
  color: #f5913e;
  transform: rotate(-35deg);
  top: 55%;
  left: 14%;
}

.calc-UI {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  margin: 10% auto;
  text-align: center;
}

.keys {
  display: flex;
}

.display {
  text-align: right;
  width: 360px;
  height: 70px;
  padding: 5% 2%;
  color: white;
  background-color: #858694;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 24px;
}

.calc-Btns {
  width: 90px;
  height: 50px;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  flex-direction: row;
  background-color: #e0e0e0;
  border: 0.5px solid #d1d1d1;
  outline: none;
}

.calc-Btns:active {
  background-color: antiquewhite;
  font-weight: bold;
}

.calc-UI > div > button:last-child {
  background-color: #f5913e;
}

.calc-UI > div:last-child > button:first-child {
  width: 180px;
}
